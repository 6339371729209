.pdf-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

.pdf-content {
  position: relative;
  width: 210mm;
  height: 297mm;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.pdf-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
  position: absolute;
  z-index: -1;
}

.overlay-text {
  display: flex;
  margin-top: 180px;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: 100%;

  color: black;
  font-family: Arial, sans-serif;
}

.overlay-text h1,
.overlay-text h2,
.overlay-text p {
}
