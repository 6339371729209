body{
    font-family: Arial, Helvetica, sans-serif;
}

table.gridtable {
  /*font-family: verdana,arial,sans-serif;*/
  /*font-size:11px;*/
  color: #333333;
  border-width: 1px;
  border-color: #666666;
  border-collapse: collapse;
  margin: 0px;
}
table.gridtable th {
  border-width: 1px;
  padding: 3px;
  border-style: solid;
  border-color: #666666;
  background-color: #dedede;
}
table.gridtable td {
  border-width: 1px;
  padding: 3px;
  border-style: solid;
  border-color: #666666;
  background-color: #ffffff;
}
#pop {
  display: block;

  top: 10%;
  left: 4%;
  padding: 10px;
  width: 940px;
  height: 85%;
  background: #C0C0C0;
  opacity: 1;
  -moz-opacity: 1;
  filter: alpha(opacity=100);
  border: 1px solid #d0d0d0;
  overflow: scroll;
  margin-top: 2%;
  margin-bottom: 10%;
  margin-right: 10%;
  margin-left: 4%;
}
#black_overlay {
  display: none;
  position: fixed;
  top: 0%;
  left: 0%;
  width: 100%;
  height: 100%;
  background-color: black;
  z-index: 1001;
  -moz-opacity: 0.9;
  opacity: 0.9;
  filter: alpha(opacity=90);
}
#divHistoricoRetificacoes,#divEmolumentos,#divParticipantes, h2{
    font-size: 18px;
    font-weight: 700;
    color: #627782;
  }
  
  