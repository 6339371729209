.p404{
 display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 80vh;
  text-align: center;
}
.p404 div img{
display: table;
  margin: auto;
  width: 70%;
}
.p404 div p {
  font-size: 20px;
}

.p404 .inc{
    text-decoration: none;
    background: #0d6efd;
    color: #fff;
    padding: 10px;
    border-radius: 5px;
    
  }
  
  .p404 .inc:hover{
    filter: brightness(90%)
  }
  nav a{
    text-decoration: none;
  }