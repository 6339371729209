.k-form-legend {
  text-align: center;
  font-size: 1.5rem;
}
.demo-section {
  width: 45%;
}
.k-form {
  width: 35%;
  margin-left: auto;
  margin-right: auto;
}
.k-form-clear {
  display: none;
}

/* Set some basic padding to keep content from hitting the edges */
/*.body-content {
    padding-left: 15px;
    padding-right: 15px;
}

/* Carousel */
.carousel-caption p {
  font-size: 20px;
  line-height: 1.4;
}

/* Make .svg files in the carousel display properly in older browsers */
.carousel-inner .item img[src$=".svg"] {
  width: 100%;
}

/* QR code generator */
#qrCode {
  margin: 15px;
}

/* Hide/rearrange for smaller screens */
@media screen and (max-width: 767px) {
  /* Hide captions */
  .carousel-caption {
    display: none;
  }
}
@media (max-width: 991px) {
  .tj-busca-proc {
    margin-top: 15px !important;
  }
}

/*Painel de Servicos*/
.tj-panel {
  background-color: #f4f4f4;
  margin-bottom: 25px;
  position: relative;
}
.tj-panel-servicos .icon-bg {
  position: absolute;
  font-size: 70px;
  right: -10px;
  top: 10px;
  opacity: 0.2;
  color: #666666;
  transition: all 0.5s ease;
}
.tj-panel-servicos h5 {
  color: #727272;
  font-weight: normal;
}
.tj-panel-servicos:hover .icon-bg {
  transform: scale(0.7);
}
.tj-panel-border {
  border-left: #727272 5px solid;
}
.tj-panel-border:hover {
  border-left: #d32f2f 5px solid;
}
.tj-panel-servicos {
  transition: all 0.5s ease;
  height: 80px;
}
.tj-panel-servicos span {
  height: 100%;
  text-decoration: none;
  cursor: pointer;
  color: #9c9b9b;
  font-size: 14px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  height: 80px;
  line-height: 18px;
}
.tj-panel-servicos:hover span {
  color: #fff;
}
.tj-panel-processo {
  border: 1px solid #cccccc;
  border-left: 5px solid #8e0000;
}
.tj-panel-consulta {
  height: 100px;
  padding: 0 20px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  height: 100px;
  border: 1px solid #cccccc;
  border-left: 5px solid #8e0000;
}
.tj-panel-consulta a {
  color: #8e0000;
}
.tj-form-group label {
  margin-bottom: 3px;
  font-weight: normal;
  color: #8e0000;
}
.tj-form-control {
}
.form-control.tj-form-control {
  border-radius: 0;
}
.form-control.tj-form-control:focus {
  border-color: #8e0000;
  outline: 0;
  box-shadow: none;
}
.tj-panel.tj-panel-processo {
  padding-top: 15px;
  min-height: 100px;
}
.btn.btn-search {
  background-color: #d32f2f;
  color: #fff;
  padding: 3px 6px;
}
.btn-search i {
  font-size: 25px;
}
.search-container {
  margin-top: 23px;
}
.title {
  font-size: 23px;
  text-transform: uppercase;
  padding-bottom: 5px;
  margin-bottom: 26px;
  border-bottom: 2px solid #eee;
  color: #037a6c;
}
.tjsp-dsp-block {
  display: block;
}
.tj-dsp-inline {
  display: inline-block;
}
.tj-slogan {
  color: #d32f2f;
  font-weight: 500;
}
.tj-width-full .iframe {
  width: 100% !important;
}
.tj-section-helpers {
  float: right;
  margin-top: 32px;
  margin-bottom: 10px;
}
.tj-section-title h2 {
  float: left;
}
.tj-section-helpers a {
  color: #000;
  margin-left: 10px;
}
.tj-section-helpers i {
  color: #8e0000;
  font-size: 18px;
}
.of-h {
  overflow: hidden;
}
.p5 {
  padding: 5px;
}
.p10 {
  padding: 10px;
}
.p15 {
  padding: 15px;
}
.p20 {
  padding: 20px;
}
.f-light {
  font-weight: lighter;
}
.hidde {
  display: none;
}
.hidde img {
  vertical-align: middle;
}
.clear {
  clear: both;
}
.tj-word-wrap {
  word-wrap: break-word;
}
.tj-font-size20 {
  font-size: 20px;
}
.tj-font-size25 {
  font-size: 25px;
}
.tj-frame-1px {
  border: 1px solid grey;
}
.tj-font-size30 {
  font-size: 30px;
}
.tj-bg-consultaP:hover {
  background-color: #e3e3e3;
}
.tj-bg-vec {
  color: #686868;
  background-color: #f3f3f3;
}
.dark-primary-color {
  background: #d32f2f;
}
.default-primary-color {
  background: #f44336;
}
.light-primary-color {
  background: #ffcdd2;
}
.text-primary-color {
  color: #ffffff;
}
.text-red-color {
  color: #f44336;
}
.accent-color {
  background: #448aff;
}
.primary-text-color {
  color: #212121;
}
.secondary-text-color {
  color: #727272;
}
.divider-color {
  border-color: #b6b6b6;
}

.tj-legend {
  display: block;
  width: 100px;
  padding: 10px;
  margin-bottom: 20px;
  font-size: 21px;
  line-height: inherit;
  color: #333;
  border: 0;
}
.tj-list-style-none,
.tj-list-style-none ul {
  list-style: none;
}
.tj-display-block {
  display: block;
}
.tj-pleft-0 {
  padding-left: 0;
}
.tj-mlRight-15px {
  margin-right: 15px;
}
.tj-mlRight-30px {
  margin-right: 30px;
}
.tj-mleft-15px {
  margin-left: 15px;
}
.tj-mtop-15px {
  margin-top: 15px;
}
.tj-ptop-15px {
  padding-top: 15px;
}
.tj-mtop-25px {
  margin-top: 25px;
}
.tj-mleft-30px {
  margin-left: 30px;
}
.tj-mtop-30px {
  margin-top: 30px;
}

/*Header cima*/
.tj-headerbar {
  min-height: 30px;
  background-color: #212121;
}
.tj-socialLinks {
  margin-top: 5px;
}
.tj-socialLinks a {
  display: inline;
}
.tj-socialLinks i {
  color: #fff;
  font-size: 20px;
  margin: 0 2px;
  transition: all 0.1s ease;
}
.tj-socialLinks i.fa-twitter:hover {
  color: #2aa9e0;
  transform: scale(1.1);
}
.tj-socialLinks i.fa-facebook-square:hover {
  color: #3b5998;
  transform: scale(1.1);
}
.tj-socialLinks i.fa-flickr:hover {
  color: #ff0084;
  transform: scale(1.1);
}
.tj-socialLinks i.fa-foursquare:hover {
  color: #fa4778;
  transform: scale(1.1);
}
.tj-socialLinks i.fa-instagram:hover {
  color: #9d6d57;
  transform: scale(1.1);
}
.tj-socialLinks i.fa-youtube-play:hover {
  color: #ee1c1b;
  transform: scale(1.1);
}
.tj-socialLinks i.fa-rss:hover {
  color: #ff7f00;
  transform: scale(1.1);
}
.tj-ajudaLinks-mobile {
  color: #fff;
  font-size: 20px;
  margin: 0 2px;
}
.tj-ajudaLinks-mobile i {
  color: #fff;
}
.tj-ajudaLinks {
  margin: 7px 0 5px 0;
  color: #fff;
  font-size: 11px;
}
.tj-ajudaLinks-Left {
  margin: 7px 15px 5px 0;
  color: #fff;
  font-size: 15px;
  /*font-weight: bold;*/
}
.tj-ajudaLinks a {
  color: #fff;
  font-size: 11px;
  font-weight: bold;
  margin-left: 5px;
  margin-right: 5px;
}
.tj-ajudaLinks a:active {
  color: #fff;
}
.tj-ajudaLinks a:visited {
  color: #fff;
}
.tj-ajudaLinks a:hover {
  text-decoration: none;
}
.tj-ajudaLinks a:last-child:hover {
  color: #ffff00;
}

/*Header*/
@media (max-width: 980px) {
  .tj-logo-reader img {
    height: 60px !important;
  }
  .tj-logo-reader div label {
    font-size: 17px !important;
  }
  .tj-logo-reader div text {
    font-size: 14px !important;
  }
}
.tj-header {
  min-height: 90px;
  padding-top: 15px;
}
.tj-logo-reader img {
  height: 70px;
  float: left;
}
.tj-logo-reader div {
  display: inline-block;
  padding-left: 10px;
  margin: 2px 0 0 10px;
}
.tj-logo-reader div label {
  color: #f44336;
  font-size: 24px;
  display: block;
}
.tj-logo-reader div text {
  display: block;
  color: #727272;
  font-size: 16px;
  line-height: 10px;
}
.tj-logo-reader span {
  display: inline-block;
  padding-left: 20px;
  margin: 7px 0 0 20px;
  border-left: 1px solid #ccc;
}
.tj-pesquisa {
  margin-top: 12px;
}
.tj-pesquisa input {
  box-shadow: none;
  height: 35px;
  border: 1px solid #ccc;
  padding: 0 5px;
  width: 100%;
  color: #262626;
}
.tj-pesquisa a i {
  color: #828282;
  font-size: 20px;
}
.tj-pesquisa .fa {
  position: absolute;
  padding: 7px;
  /*pointer-events: none;*/
}
.tj-pesquisa-direita .fa {
  right: 15px;
}

/*footer*/
.tj-footer {
  min-height: 300px;
  padding-top: 15px;
  background-color: #f4f4f4;
  border-top: 30px solid #f9f9f9;
}
.tj-footer-a {
  margin-bottom: 30px;
  border-right: solid 1px #fff;
  height: 100% !important;
}
.tj-footer-a a {
  display: block;
  color: #727272;
  font-size: 12px;
  line-height: 16px;
  margin-bottom: 15px;
}
.tj-footer-a a:hover {
  display: block;
  color: #f44336;
  font-size: 12px;
  line-height: 16px;
  margin-bottom: 15px;
}
.tj-footer-a H4 {
  font-weight: bold;
  color: #727272;
  margin-bottom: 20px;
}
.tj-footerbar {
  min-height: 100px;
  background-color: #212121;
}
.tj-footerbar p {
  color: #fff;
  line-height: 20px;
}
footer .tj-socialLinks i {
  color: #727272;
}

/*endfooter*/
.form-control-login {
  display: block;
  width: 100%;
  height: 40px;
  padding: 6px 12px;
  border: 1px solid #cacaca;
  transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
  -moz-transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
  -webkit-transition: border-color ease-in-out 0.15s,
    box-shadow ease-in-out 0.15s;
}
.form-control-login:focus {
  border-color: #0071bc;
  /*box-shadow: 0 0 8px #f59fa6;*/
  outline: 0;
}
.btn-login {
  border-radius: 0 !important;
  color: #ffffff !important;
  background-color: #0071bc;
  font-weight: bold;
  font-size: 17px;
}
.btn-login:hover {
  background-color: #0289d3;
  color: #ffffff;
}
.login-box {
  max-width: 400px;
  width: 100%;
  background-color: #fff;
  border: 1px solid #cacaca;
  padding: 20px 32px;
  margin: 0 auto;
}

/* Tooltip container */
.tooltiip {
  position: relative;
  display: inline-block;
  border-bottom: 1px dotted black;
}

/* Tooltip text */
.tooltiip .tooltiiptext {
  visibility: hidden;
  width: 230px;
  background-color: black;
  color: #fff;
  text-align: left;
  padding: 5px 0;
  border-radius: 6px;
  position: absolute;
  z-index: 1;
}

/* Show the tooltip text when you mouse over the tooltip container */
.tooltiip:hover .tooltiiptext {
  visibility: visible;
}
.icon-round {
  background-color: #3696f1;
  padding: 10px;
  width: 60px;
  height: 60px;
  border-radius: 31px;
  text-align: center;
  margin: 0 auto;
  margin-bottom: 10px;
}
.icon-round i {
  font-size: 40px;
  color: #fff;
}
