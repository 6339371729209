#geral{
	width:100%;
	min-height:100%;
	position:relative;
	z-index:50;
	background:url(//selo.tjsc.jus.br/html/imagens/back_circuito.png) no-repeat left top;
}
* html #geral { height: 100%; }

table th{
	background:#e3e3e3;
	text-align:left;
	padding:5px;
}
table td{
	padding:5px;
}
fieldset{
	padding:5px 15px 10px 15px;
}
legend{
	color:#447aa2;
	font-weight:bold;
}


/* cabeçalho */
#cabecalho{
	border-top:10px solid #447aa2;
}
#contato{
	width:85px;
	height:30px;
	float:right;
	font-family:'ZagRegular', 'Terminal Dosis Light', "Trebuchet MS", Arial, Helvetica, sans-serif;
	font-size:20px;
	padding-left:65px;
	margin-right:50px;
	background:url(//selo.tjsc.jus.br/html/imagens/aba_contato.png) no-repeat;
}
#contato a:link{
	color:#fafafa;
	font-weight:normal !important;
	text-decoration:none;
}
#contato a:hover{
	color:#aad4f5;
}
#contato a:visited{
	color:#fafafa;
	text-decoration:none;
}
#contato a:active{
	color:#aad4f5;
}
.marcas{
	width:35px;
	height:35px;
	float:right;
	margin:5px;
}
#marcas_tjsc:link{
	background:url(//selo.tjsc.jus.br/html/imagens/marcas_tjsc.png) no-repeat top;
}
#marcas_tjsc:hover{
	background:url(//selo.tjsc.jus.br/html/imagens/marcas_tjsc.png) no-repeat bottom;
}
#marcas_tjsc:visited{
	background:url(//selo.tjsc.jus.br/html/imagens/marcas_tjsc.png) no-repeat top;
}
#marcas_extra:link{
	background:url(//selo.tjsc.jus.br/html/imagens/marcas_extra.png) no-repeat top;
}
#marcas_extra:hover{
	background:url(//selo.tjsc.jus.br/html/imagens/marcas_extra.png) no-repeat bottom;
}
#marcas_extra:visited{
	background:url(//selo.tjsc.jus.br/html/imagens/marcas_extra.png) no-repeat top;
}
#marcas_cgj:link{
	background:url(//selo.tjsc.jus.br/html/imagens/marcas_cgj.png) no-repeat top;
}
#marcas_cgj:hover{
	background:url(//selo.tjsc.jus.br/html/imagens/marcas_cgj.png) no-repeat bottom;
}
#marcas_cgj:visited{
	background:url(//selo.tjsc.jus.br/html/imagens/marcas_cgj.png) no-repeat top;
}

/* central página inicial */
#central{
	width:800px;
	margin:auto;
	clear:both;
}
#marca_selo{
	margin:20px 0 20px 0;
}
#lupa{
	width:512px;
	height:430px;
	float:left;
	background:url(//selo.tjsc.jus.br/html/imagens/detalhes_selo.png) no-repeat top;
}

#lupa_destaque{
	width:512px;
	height:25px;
	margin-top:205px; 

}

.lupa_destaque_codigo{
	margin-top:204px; 
	background:url(//selo.tjsc.jus.br/html/imagens/detalhes_selo_codigo_destaque.png) no-repeat top;
}

.lupa_destaque_verificador{
	margin-top:204px; 
	background:url(//selo.tjsc.jus.br/html/imagens/detalhes_selo_codigo_destaque.png) no-repeat bottom;
}

#formulario{
	width:243px;
	float:right;
}
.linha_form{
	height:77px;
}
.campos_form{
	width:200px;
	height:44px;
	padding:10px 10px 0 10px;
	background:url(//selo.tjsc.jus.br/html/imagens/back_form.png) no-repeat;
	border:none;
	float:left;
	font-family:"Trebuchet MS", Arial, Helvetica, sans-serif;
	font-size:22px;
	color:#bababa;
}
.botao{
	width:98px;
	height:38px;
	background:url(//selo.tjsc.jus.br/html/imagens/back_botao.png) no-repeat;
	float:right;
	border:none;
	font-family:"Trebuchet MS", Arial, Helvetica, sans-serif;
	color:#fff;
	font-size:12px;
	text-transform:uppercase;
	margin-top:15px;
	cursor:pointer;
	clear:both;
}
.botao:hover{
	background:url(//selo.tjsc.jus.br/html/imagens/back_botao_over.png) no-repeat;
}
/* Tooltip */
#tooltip {
	width:144px;
	position:absolute;
	z-index:9999;
	color:#fff;
	font-size:11px;
}

#tooltip .tipHeader {
	height:26px;
	background:url(//selo.tjsc.jus.br/html/imagens/balao_cima.png) no-repeat;
}


#tooltip .tipBody {
	background:url(//selo.tjsc.jus.br/html/imagens/balao_meio.png) repeat-y;
	padding:5px 5px 10px 15px;
}

#tooltip .tipFooter {
	height:6px;
}


#

#




/* central internas */
#botao_voltar{
	position:absolute;
	right:0;
	top:0;
	float:right;
	width:30px;
	font-size:10px;
	color:#999;
	font-family:"Trebuchet MS", Arial, Helvetica, sans-serif;
	padding:30px 0px 0px 20px;
}
#coluna_principal{
	width:550px;
	padding:20px 20px 10px 35px;
	float:left;
}
#coluna_auxiliar{
	width:130px;
	padding:70px 10px 30px 15px;
	float:right;
	font-size:10px;
	line-height:12px;
}
#coluna_auxiliar p{
	padding:20px 0 20px 0;
}
#coluna_principal_full{
	width:745px;
	padding:20px 20px 10px 35px;
}
#ir_topo {
	-moz-border-radius: 5px;
	-webkit-border-radius: 5px;
	border-radius: 5px;
	width:80px;
	background-color: #EEEEEE;
	background-color: rgba(238, 238, 238, 0.6);
	filter: progid:DXImageTransform.Microsoft.gradient(startColorStr='#99EEEEEE',EndColorStr='#99EEEEEE');
	text-align:center;
	padding:5px;
	position:fixed;
	bottom:10px;
	right:10px;
	cursor:pointer;
	color:#444;
	text-decoration:none;
	border:1px solid #C9C9C9;
}

/* rodape */
#rodape{
	position:absolute;
	left:0;
	z-index:100;
	width:100%;
	height:200px;
	background:url(//selo.tjsc.jus.br/html/imagens/back_rodape.png) no-repeat center top;
	clear:both;
}
#container_menu{
	width:800px;
	margin:auto;
}

h3:visited{
	color:#fafafa;
}
.menu{
	width:220px;
	height:90px;
	float:left;
	margin:0 22px 25px 22px;
	display:block;
	padding-top:20px;
	cursor:pointer;
}
.menu_titulo{
	width:167px;
	float:right;
}

#selo{
	background: url(//selo.tjsc.jus.br/html/imagens/icone_selo.png);	
}
#selo:hover{
	background: url(//selo.tjsc.jus.br/html/imagens/over_selo.jpg);
	color:#fff;
}
#selo:hover h3{
	color:#fff;
}
#selo:visited{
	text-decoration:none;
}

#normas{
	background: url(//selo.tjsc.jus.br/html/imagens/icone_normas.png);	
}
#normas:hover{
	background: url(//selo.tjsc.jus.br/html/imagens/over_normas.jpg);
	color:#fff;
}
#normas:hover h3{
	color:#fff;
}
#normas:visited{
	text-decoration:none;
}

#perguntas{
	background: url(//selo.tjsc.jus.br/html/imagens/icone_perguntas.png);	
}
#perguntas:hover{
	background: url(//selo.tjsc.jus.br/html/imagens/over_perguntas.jpg);
	color:#fff;
}
#perguntas:hover h3{
	color:#fff;
}
#perguntas:visited{
	text-decoration:none;
}



table.cgj {
	border-width: 1px;
	border-spacing: 0px;
	border-style: none;
	border-color: LightGray;
	border-collapse:
	collapse;
	}
	table.cgj th {
	border-width: 1px;
	padding: 3px;
	border-style: solid;
	border-color: LightGray;
	-moz-border-radius: ;
	background-color: LightGray;
	}
	table.cgj td {
	border-width: 1px;
	padding: 3px;
	border-style: solid;
	border-color: LightGray;
	-moz-border-radius: ;
	}
	table.cgj2 {
	border-width: 1px;
	border-spacing:
	0px;
	border-style: none;
	border-color: LightGray;
	border-collapse:
	collapse;
	}
	table.cgj2 th {
	border-width: 1px;
	padding: 3px;
	border-style: solid;
	border-color: LightGray;
	-moz-border-radius: ;
	background-color: LightGray;
	}
	table.cgj2 td {
	border-width: 1px;
	padding: 3px;
	border-style: solid;
	border-color: LightGray;
	-moz-border-radius: ;
	}
	@media print {
	#cabecalho {display: none;}
	#rodape {display: none;}
	#background {display: none;}
	}

