.ui-panel .ui-panel-content {
  border: 0;
  background: 0;
  padding: .5em 1em;
}

.ui-datatable .ui-datatable-header {
  border-bottom: 0 none;
}
.ui-datatable .ui-datatable-header, .ui-datatable .ui-datatable-footer {
  text-align: center;
  padding: 4px 10px;
}
.ui-datatable thead th, .ui-datatable tbody td, .ui-datatable tfoot td {
  white-space: normal;
}
.ui-datatable thead th, .ui-datatable tbody td, .ui-datatable tfoot td, .ui-datatable tfoot th {
  padding: 4px 10px;
  overflow: hidden;
  border-width: 1px;
  border-style: solid;
}
.ui-datatable thead th, .ui-datatable tfoot td {
  text-align: center;
}
th.ui-state-default, .ui-widget-header, .ui-widget-header td.ui-state-default {
  background-color: #e9e9e9;
  font-weight: bold;
  border-color: #aaaaaa;
  background-image: none;
  color: #555555;
}
.ui-state-default, .ui-widget-content .ui-state-default, .ui-widget-header .ui-state-default {
  border: 1px solid #d3d3d3;
    border-top-width: 1px;
    border-top-style: solid;
    border-top-color: rgb(211, 211, 211);
    border-right-width: 1px;
    border-right-style: solid;
    border-right-color: rgb(211, 211, 211);
    border-bottom-width: 1px;
    border-bottom-style: solid;
    border-bottom-color: rgb(211, 211, 211);
    border-left-width: 1px;
    border-left-style: solid;
    border-left-color: rgb(211, 211, 211);
     background-color: rgb(230, 230, 230);
      font-weight: normal;
  color: #555555;
}