.guWPuq {
  position: fixed;
  width: 100%;
  height: 100%;
  background: rgba(10, 30, 66, 0.4);
  display: flex;
  -moz-box-pack: center;
  justify-content: center;
  -moz-box-align: center;
  align-items: center;
  top: 0px;
  left: 0px;
  transition: opacity 100ms ease-in-out 0s;
  z-index: 340;
}

.fyGHVY {
    position: fixed;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    display: flex;
    -moz-box-align: center;
    align-items: center;
    -moz-box-pack: center;
    justify-content: center;
    transition: opacity 180ms ease 0s, transform 300ms ease-in-out 0s;
    z-index: 350;
  }

  .kVYZTt {
    position: relative;
    background-color: rgb(255, 255, 255);
    border-radius: 15px;
    width: 496px;
  }
  

  .eCuIZO {
    position: relative;
    display: flex;
    -moz-box-align: center;
    align-items: center;
    padding: 24px;
    min-height: 62px;
    gap: 12px;
    border-bottom: 1px solid rgb(229, 232, 235);
    -moz-box-pack: justify;
    justify-content: space-between;
  }
  .iEZsiF {
    position: relative;
    display: block;
    font-size: 24px;
    font-weight: 700;
    line-height: 1.25;
    color: rgb(13, 12, 34);
  }
  
  .hoeLrA {
    position: relative;
    color: rgb(166, 176, 195);
    background-color: transparent;
    transition: color 200ms ease-in-out 0s;
  }

  .gmRYUp {
    position: relative;
    line-height: 1.5;
    padding: 28px 24px;
    color: rgb(131, 133, 143);
    max-height: 600px;
    overflow: unset;
  }