.Cabecalho
{

	width: 766px;
	height: 92px;
	padding-top: 35px;
	text-align: Center;
}

/*Botões*/
INPUT.Botoes
{
	background-color: #D7E2EA;
	border: 2px Solid #6F8EA5;
	font-family: Tahoma, Verdana, Arial, Helvetica, Sans-Serif;
	font-size: Small;
	font-weight: Bold;
	padding: 1px;
	cursor: Hand;
	width: 90px;	
}

INPUT.BotoesLargos
{
	background-color: #D7E2EA;
	border: 2px Solid #6F8EA5;
	font-family: Tahoma, Verdana, Arial, Helvetica, Sans-Serif;
	font-size: Small;
	font-weight: Bold;
	padding: 1px;
	cursor: Hand;
	width: auto;	
}

/*Labels*/
.labelSistema
{
	 Font-Size: x-large; 
	 font-weight: Bold;
}
.labelTitulo
{
	font-size: Smaller;
}

.labelVersao
{
	font-weight: Bold;
	Font-Size: 8px;
}

.TextoVersao
{
	background-color: #EFF3F5;
	border-bottom: Solid 2px #51667E;
	overflow: Auto;
	text-align: Right;
	vertical-align: Bottom;
}

.Menu
{
	background-color: #6F8EA5;
	border-bottom: Solid 2px #51667E;
	font-family: Tahoma;
	width: 100%;
	height: 22px;
}

.ContentPlaceHolder
{
	padding-left:5px;
	width: 100%;
}

#geral
{
	background-color: #EFF3F5;
	text-align:left;
	margin-top:10px;
	width:766px;
	margin-left: auto;
	margin-right: auto;
	margin-bottom: 0;
}

/* Usar para título das páginas de cadastro de relatórios apenas */
.TituloPagina
{	
	font-family: Verdana;
	color: Black;
	font-weight: Bold;
	text-align: Center;
	font-size: Medium;
}

.dica
{
	font-size:x-small;
	/*text-align:center;*/
}

.failureNotification
{
    font-size: small;
    color: Red;
    width: 735px;
    padding-left: 5px;
}


/* no menu <DynamicMenuStyle CssClass="adjustedZIndex" /> 
   Solução para corrigir problema de renderização do menu
*/
.adjustedZIndex
{
	z-index: 1;
}

/*
a:link, a:visited {
	text-decoration: none;
	color: maroon;
}

a:hover, a:active {
	text-decoration: underline;
	color: red;
}
*/

ul#menu { width: 100%; height: 43px; background: #FFF  top left repeat-x; font-size: 0.8em; font-family: "Lucida Grande", Verdana, sans-serif; font-weight: bold; list-style-type: none; margin: 0; padding: 0; }
ul#menu li { display: block; float: left; margin: 0 0 0 5px; }
ul#menu li a { height: 43px; color: #777; text-decoration: none; display: block; float: left; line-height: 200%; padding: 8px 15px 0; }
ul#menu li a:hover { color: #333; }
ul#menu li a.current{ color: #FFF; background: #FFF  top left repeat-x; padding: 5px 15px 0; }



.btn-es{
	background-color: #D7E2EA;
  border: 2px Solid #6F8EA5;
  font-family: Tahoma, Verdana, Arial, Helvetica, Sans-Serif;
  font-size: Small;
  font-weight:700;
  padding: 1px;
  cursor: Hand;
  width: 110px;
}